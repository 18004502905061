import React from 'react';

import Hero from '../../components/06-expansion/hero';
import Intro from '../../components/06-expansion/intro';
import TradeMe from '../../components/06-expansion/trademe';
import USA from '../../components/06-expansion/usa';
import UK from '../../components/06-expansion/uk';
import Exploding from '../../components/06-expansion/exploding';
import Traction from '../../components/06-expansion/traction';

export default ({ id, className, forwardRef }) => {
  return (
    <div id={id} className={className} ref={forwardRef}>
      <div className="nav-text">
        <div>06. International Expansion</div>
      </div>
      <Hero />
      <Intro />
      <div className="hidden lg:block">
        <TradeMe />
      </div>
      <USA />
      <UK />
      <Exploding />
      <Traction />
    </div>
  );
};
