import React from 'react';
import { useInView } from 'react-intersection-observer';

import StatBar from '../stat-bar';
import Polycluster from '../../images/06-expansion/traction-polycluster.inline.svg';

export default () => {
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  const [refStats, inViewStats] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="flex flex-col lg:flex-row overflow-hidden">
      <div
        className="relative w-full lg:w-2/3 flex-no-shrink mt-64"
        style={{
          background:
            'linear-gradient(0.45deg, #143673 0.37%, #FF5A7D 99.77%), #FF5A7D',
        }}
      >
        <div
          className="absolute w-px bg-neon-lavender -mt-32 ml-32 md:ml-48 lg:ml-64"
          style={{
            height: `${inView ? '16rem' : '0'}`,
            transition: 'height 300ms',
          }}
        />
        <div className="pt-20 sm:pt-32 px-6 sm:px-12 md:px-20 lg:pl-40 lg:pr-24 xl:pl-48">
          <div
            ref={ref}
            className="relative inline-flex px-3 highlight-text text-white"
          >
            <div
              className="absolute pin-y pin-l bg-neon-lavender"
              style={{
                right: inView ? '0' : '100%',
                transition: 'right 250ms 250ms',
              }}
            />
            <div
              className="relative z-10"
              style={{
                opacity: inView ? 1 : 0,
                transition: 'opacity 250ms 250ms',
              }}
            >
              Early traction in{' '}
              <span className="block sm:inline">the US market</span>
            </div>
          </div>
          <div className="lg:pr-32 xl:pr-64 max-w-lg">
            <p
              className={`body-text text-white my-24 lg:mb-32 xl:mb-64 animated delay-500ms ${
                inView ? 'fadeIn' : 'fadeOut'
              }`}
            >
              While still early days in the US market, the results so far have
              been astonishing. Afterpay has already acquired over{' '}
              <span
                className={`transition-color transition-1s transition-delay-1s ${
                  inView ? 'text-turquoise' : 'text-white'
                }`}
              >
                2.1 million new customers and over 4,700 retailers.
              </span>{' '}
              The pipeline continues to be strong with around{' '}
              <span
                className={`transition-color transition-1s transition-delay-1s ${
                  inView ? 'text-turquoise' : 'text-white'
                }`}
              >
                6,500 retailers
              </span>{' '}
              having signed agreements with Afterpay or are alrady in the process
              of integrating on the US platform. It took the Australian business
              approximately 28 months to deliver the $260 million of underlying
              sales that the US business achieved in the first six months of FY19.
            </p>
          </div>
        </div>
      </div>
      <div
        ref={refStats}
        className="stat-towers relative lg:self-end lg:-mb-16 xl:-mb-32"
      >
        <Polycluster className="hidden lg:block z-10 absolute pin-t pin-r lg:pin-l -mt-20 -ml-12" />
        <StatBar
          className="text-navy"
          background="linear-gradient(90deg, #FF5A7D 9.32%, #B1F8FC 100%), #FF5A7D"
          text="4,700+"
          subText="US Retailers"
          inView={inViewStats}
        />
        <StatBar
          className="text-navy"
          background="#b1f8fc"
          text="2,100,000"
          subText="New Customers"
          inView={inViewStats}
        />
        <StatBar
          className="text-white"
          background="linear-gradient(270deg, #C99CEF 0.07%, #143673 99.97%), #143673"
          text="$940 Million"
          subText="Underlying Sales"
          inView={inViewStats}
        />
      </div>
    </div>
  );
};
