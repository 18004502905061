import React from 'react';

import AfterpayTradeMe from '../../images/06-expansion/afterpay-trademe.inline.svg';
import APFlag from '../../images/06-expansion/ap-flag.inline.svg';
import KiwiLeftFoot from '../../images/06-expansion/kiwi-left-foot.inline.svg';
import KiwiRightFoot from '../../images/06-expansion/kiwi-right-foot.inline.svg';
import Circle from '../../images/circle.inline.svg';
import TriangleCircle from '../../images/triangle-circle.inline.svg';
import StickyScroller from '../sticky-scroller';
import LazyLoadWrap from '../lazy-load-wrap';

const Trademe = () => {
  return (
    <React.Fragment>
      <StickyScroller top="25vh" scrollHeight="200vh" contentHeight="75vh">
        {({
          inViewRatio,
          scrollProgress,
          topProgress,
          bottomProgress,
          rect,
        }) => {
          const sine = Math.sin(scrollProgress * 100);

          // Kiwi
          const kiwiX = 1 - scrollProgress;
          const kiwiY = bottomProgress <= 1 ? (1 - bottomProgress) * 200 : 0;
          const kiwiDeg =
            scrollProgress >= 0.9 ? (scrollProgress % 0.9) / 0.1 : 0;

          // Flag
          const [flagDeg, flagTransformOrigin] =
            scrollProgress >= 0.9
              ? [((scrollProgress % 0.9) / 0.1) * -360, 'center']
              : [sine * 3, 'bottom right'];

          const flagXY =
            bottomProgress < 1 && bottomProgress > 0.9
              ? (1 - bottomProgress) / 0.1
              : bottomProgress < 0.9
              ? 1
              : 0;

          // Sun
          let sunY =
            topProgress >= 0.5 && topProgress <= 1
              ? topProgress * (1 / 0.5) - 1
              : topProgress >= 1
              ? 1
              : 0;

          if (bottomProgress < 0.8) {
            sunY = bottomProgress / 0.8;
          }

          return (
            <div className={`h-full overflow-hidden`}>
              <div
                className="relative max-w-2xl mx-auto"
                style={{ height: '60vh' }}
              >
                <div
                  className="absolute pin-b pin-r -mr-16 z-20 block"
                  style={{
                    transformOrigin: 'bottom',
                    transform: `translateZ(0) translateX(${kiwiX *
                      -210}%) translateY(${sine}%) scale(${0.5 +
                      (1 - scrollProgress) * 0.5})`,
                  }}
                >
                  <div
                    className="relative"
                    style={{
                      transformOrigin: 'bottom',
                      transform: `translateZ(0) translateY(${kiwiY}%) rotate(${kiwiDeg *
                        180}deg)`,
                    }}
                  >
                    <AfterpayTradeMe />
                    <KiwiLeftFoot
                      className="block absolute z-10"
                      style={{
                        left: '168px',
                        bottom: '0',
                        transform: `translateZ(0) rotate(${scrollProgress * 3600 * 2}deg)`,
                      }}
                    />
                    <KiwiRightFoot
                      className="block absolute"
                      style={{
                        left: '370px',
                        bottom: '0',
                        transform: `translateZ(0) rotate(${scrollProgress * 3600 * 2}deg)`,
                      }}
                    />
                  </div>
                  <APFlag
                    id="ap-flag"
                    className="block pin-t pin-l absolute"
                    style={{
                      transformOrigin: `${flagTransformOrigin}`,
                      transform: `translateZ(0) rotate(${flagDeg}deg) translate(${flagXY *
                        26}%, ${flagXY * -138}%)`,
                    }}
                  />
                </div>
                <div className="absolute pin-b pin-r -mr-32">
                  <Circle
                    className="block absolute pin-x mx-auto"
                    fill="url(#linear-gradient-special-red-to-neon-lavender)"
                    style={{
                      opacity: `${sunY}`,
                      transform: `translateZ(0) translateY(${50 + (1 - sunY) * 50}%)`,
                    }}
                    height={550}
                  />
                  <TriangleCircle
                    id="kiwi-triangle"
                    height={550}
                    className="block relative z-10"
                    fill="url(#linear-gradient-neon-lavender-to-navy)"
                    style={{
                      transform: `translateZ(0) translateY(${50 +
                        (1 - scrollProgress) * 50}%)`,
                    }}
                  />
                </div>
              </div>
              <div
                className="relative z-20 bg-neon-lavender"
                style={{
                  height: '15vh',
                }}
              />
            </div>
          );
        }}
      </StickyScroller>
    </React.Fragment>
  );
};

export default LazyLoadWrap(Trademe, '200vh');

