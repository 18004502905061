import React from 'react';
import { useInView } from 'react-intersection-observer';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className="px-6 sm:px-12 md:px-20 lg:pl-40">
      <div className="max-w-xl mx-auto">
        <div className="flex flex-col lg:flex-row">
          <div className="my-12 lg:pr-12">
            <p
              className={`body-text text-navy animated ${
                inView ? 'fadeIn' : 'fadeOut'
              }`}
            >
              The pace of international growth has required a substantial
              investment into the architecture of the Afterpay platform. This
              behind-the-scenes work on both the web and mobile platform
              includes automating build pipelines, managing conditional
              functionality by market, supporting region-specific operating
              teams and extending cloud infrastructure services.
            </p>
          </div>
          <div className="my-12 lg:mt-24 lg:pl-12">
            <p
              className={`highlight-text-expanded text-navy delay-500ms animated ${
                inView ? 'fadeIn' : 'fadeOut'
              }`}
            >
              Despite the explosion in complexity, the Afterpay platform has
              <span
                className={`transition-color transition-1s transition-delay-1s ${
                  inView ? 'text-special-red' : 'text-navy'
                }`}
              >
                {' '}
                successfully launched in three new markets across the world in the
                space of 16 months.
              </span>{' '}
              The growth in these markets is already exploding.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
