import React from 'react';
import { useInView } from 'react-intersection-observer';

export default () => {
  const [ref, inView] = useInView({
    threshold: 1,
    rootMargin: '0px 0px -12.5% 0px',
    triggerOnce: true,
  });

  return (
    <div className="bg-white pt-32 md:pt-64 pb-24 px-8 sm:px-12 md:px-32 lg:pl-56 xl:pl-64">
      <div className="max-w-xl body-text text-navy">
        <p
          ref={ref}
          className={`animated delay-500ms mb-12 ${
            inView ? 'fadeIn' : 'fadeOut'
          }`}
        >
          Not content with being the fastest growing technology company in
          Australia, in 2017 Afterpay launched a grand international expansion
          plan that continues through to the present day.
        </p>
        <p className={`animated delay-1s ${inView ? 'fadeIn' : 'fadeOut'}`}>
          In September 2017, Afterpay crossed the Tasman and launched into the New
          Zealand market by partnering with Trade Me. This move allowed Afterpay
          and Paloma to internationalise the product, and marked the first
          step in Afterpay’s global ambitions.
        </p>
      </div>
    </div>
  );
};
