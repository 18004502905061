import React from 'react';

export default ({ className, text, subText, background, inView }) => (
  <div className={`stat-bar ${inView ? 'in-view' : ''}`}>
    <div
      className={`relative lg:inline-flex justify-center flex-col py-6 lg:pt-12 lg:pb-10 px-6 sm:px-12 md:px-20 lg:px-24 h-full ${className}`}
    >
      <div className="bg-bar absolute pin-y pin-l" style={{ background }} />
      <div className="relative z-10 border-t border-b border-transparent">
        <div className="big-stat-text whitespace-no-wrap">{text}</div>
        <div className="date-text whitespace-no-wrap">{subText}</div>
      </div>
    </div>
  </div>
);
