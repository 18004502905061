import React from 'react';
import { useInView } from 'react-intersection-observer';

import TriangleDownCircle from '../../images/triangle-down-circle.inline.svg';
import USA from '../../images/06-expansion/usa.inline.svg';
import Polycluster from '../../images/06-expansion/usa-polycluster.inline.svg';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div
      id="usa"
      className="relative z-10"
    >
      <div className="max-w-2xl mx-auto overflow-hidden">
        <div className="ml-auto -mr-32" style={{ width: 672 }}>
          <div
            className="hidden lg:block"
            style={{
              transform: `translateZ(0) skew(-65deg) translateX(0%) scaleY(${
                inView ? 1 : 0
              })`,
              transformOrigin: 'top center',
              transition: 'transform 250ms',
            }}
          >
            <TriangleDownCircle
              className="block mx-auto"
              fill="url(#linear-gradient-neon-lavender-to-white)"
              width={336}
            />
          </div>
        </div>
        <div ref={ref} className="relative pt-24 lg:pt-0 lg:pl-24 xl:pl-0">
          <div
            className="absolute invisible lg:visible"
            style={{
              transform: `translateZ(0) rotate(15deg) skewX(62deg) translate(55%, 10%) scaleY(${
                inView ? 1 : 0
              })`,
              transformOrigin: 'top center',
              transition: 'transform 250ms 1s',
            }}
          >
            <TriangleDownCircle
              className="block mx-auto"
              fill="url(#linear-gradient-turquoise-to-white)"
              width={336}
            />
          </div>
          <div className="relative z-10 px-6 sm:px-12 md:px-20 mx-auto lg:ml-0 max-w-md overflow-hidden">
            <div
              className={`flex items-center mb-8 animated ${
                inView ? 'fadeIn' : 'fadeOut'
              }`}
            >
              <div className="relative z-10 w-1/2">
                <div className="date-text text-special-red">
                  <span className="bg-turquoise">May 2018</span>
                </div>
                <div className="big-stat-text text-navy">USA</div>
              </div>
              <div className="w-full lg:w-1/2 relative -ml-32 lg:-ml-24">
                <USA className="block w-full lg:w-auto" />
              </div>
            </div>
            <p
              className={`body-text text-navy mb-16 animated delay-250ms ${
                inView ? 'fadeIn' : 'fadeOut'
              }`}
            >
              Nine months later, Afterpay launched in the US, the land of
              retail. With more retail spending in the state of California than
              the whole of Australasia, success in the US has the potential to
              transform Afterpay into a global payments giant.
            </p>
          </div>
          <Polycluster className="lg:absolute block z-10 pin-x py-5 lg:mr-0 mx-auto pin-y my-auto pin-r" />
        </div>
      </div>
    </div>
  );
};
