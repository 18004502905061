import React from 'react';

import HeroText from '../../components/hero-text';
import HighlightTextHeading from '../../components/highlight-text-heading';

import Globe from '../../images/06-expansion/hero-globe.svg';
import ScrollTracker from '../scroll-tracker';
import PolyclusterLg from '../polycluster-lg';
import isMobile from '../../utils/is-mobile';
import LazyLoadWrap from '../lazy-load-wrap';

const hero = () => {
  return (
    <ScrollTracker>
      {({ rect: { top, height } }) => {
        const mobile = isMobile();
        const offsetY = mobile ? 0 : top < height && top > -height ? top : 0;

        return (
          <div className="hero">
            <div
              className="bg overflow-hidden"
              style={{
                background: `linear-gradient(130deg, #B1F8FC 30%, #143673 68%), #143673`,
              }}
            >
              <div className="absolute center">
                <div style={{ transform: `translateZ(0) translateY(${-offsetY / 20}px)` }}>
                  <img className="block" src={Globe} alt="globe" />
                </div>
              </div>
            </div>
            <div className="hero-text-container">
              <HeroText
                date="September 2017 – Present Day"
                lineA="Going"
                lineB="Global"
              />
              <HighlightTextHeading
                bgColor="neon-lavender"
                textColor="white"
                lineColor="neon-lavender"
              >
                Taking flight
              </HighlightTextHeading>
            </div>
            {!mobile && (
              <div className="absolute w-full z-10" style={{ width: '30vw', right: '5vw', bottom: 0 }}>
                <PolyclusterLg
                  a="#C99CEF"
                  b="#B1F8FC"
                  c="#FF5A7D"
                  d="#C99CEF"
                  e="#B1F8FC"
                  className="w-full"
                  offsetY={offsetY}
                />
              </div>
            )}
          </div>
        );
      }}
    </ScrollTracker>
  );
};

export default LazyLoadWrap(hero);
