import React from 'react';
import { useInView } from 'react-intersection-observer';

import UK from '../../images/06-expansion/uk.inline.svg';
import Circle from '../../images/circle.inline.svg';
import ScrollTracker from '../scroll-tracker';
import PolyclusterLg from '../polycluster-lg';
import isMobile from '../../utils/is-mobile';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <ScrollTracker>
      {({ rect: { top, height } }) => {
        const mobile = isMobile();
        const offsetY = mobile
          ? 0
          : top < height && top > -height
          ? top
          : 0;

        return (
          <div>
            <div className="relative max-w-2xl mx-auto">
              <div
                className="absolute absolute pin-r"
                style={{ width: '38.1944444444vw', height: '38.1944444444vw' }}
              >
                <Circle
                  className="block"
                  width="100%"
                  height="100%"
                  fill="url(#linear-gradient-neon-lavender-to-turquoise)"
                  style={{
                    transform: `translateZ(0) translateY(${offsetY / 5}px)`,
                  }}
                />
              </div>
            </div>
            <div ref={ref} className="relative z-10 lg:w-3/4 lg:ml-auto">
              <UK width="100%" height="100%" className="block" />
            </div>
            <div
              className="relative z-10"
              style={{
                background:
                  'linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #B1F8FC',
              }}
            >
              <div className="max-w-2xl mx-auto">
                <div
                  className="relative mx-auto lg:ml-auto lg:mr-0 max-w-md px-6 sm:px-12 md:px-20 lg:px-6"
                  style={{ top: '-7rem' }}
                >
                  <div
                    className={`mb-16 lg:mb-8 animated ${
                      inView ? 'fadeIn' : 'fadeOut'
                    }`}
                  >
                    <div className="inline-flex px-3 date-text text-turquoise bg-special-red">
                      May 2019
                    </div>
                    <div className="big-stat-text text-navy">UK</div>
                  </div>
                  <p
                    className={`body-text text-navy animated delay-250ms ${
                      inView ? 'fadeIn' : 'fadeOut'
                    }`}
                  >
                    In May 2019, Afterpay launched in the UK following the
                    acquisition of local company ClearPay. With a $720 billion market,
                    the UK is over twice the size of the Australian market.
                  </p>
                </div>
              </div>
            </div>
            <PolyclusterLg className="w-full mx-auto" offsetY={offsetY} />
          </div>
        );
      }}
    </ScrollTracker>
  );
};
